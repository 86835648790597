$(document).ready(() => {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").addClass("navbar--scrolled");
    $(".menu__wrapper")
      .delay(500)
      .fadeIn(250);
  };

  const menuClose = () => {
    $(".menu__wrapper").fadeOut(250);
    setTimeout(function() {
      $("#menu").removeClass("menu--opened");
    }, 250);
    setTimeout(function() {
      $("body").css("overflow", "auto");
      if (!($(window).scrollTop() > 50)) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }, 300);
  };

  $(".hamburger").on("mouseenter", event => {
    $("#hamburgerMenu").slideUp(250);
    setTimeout(function() {
      if ($(".hamburger").hasClass("hamburger--clicked")) {
        $("#hamburgerClose").slideDown(250);
      } else {
        $("#hamburgerOpen").slideDown(250);
      }
    }, 0);
  });

  $(".hamburger").on("mouseleave", event => {
    $("#hamburgerOpen, #hamburgerClose").slideUp(250);
    setTimeout(function() {
      $("#hamburgerMenu").slideDown(250);
    }, 0);
  });

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });
});
